<template>
  <Popup
    :title="`광고 ${isModify ? '수정':'등록'}`"
    :maxWidth="800"
    closeBtnText="닫기"
    :compeleteBtnText="isModify ? '수정':'등록'"
    :isDisabeldCompeleteBtn="isVaild"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="onClickComplete()">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px" />
        <col />
        <col style="width:140px" />
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>섬네일<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <FileUpload
              :dataList.sync="model.imageList"
              :noticeArray="['모바일 전용으로 용량을 줄여 등록해주세요']"
              :canMultiple="false"
              :acceptList="['jpg','jpeg','png']"
              sizeType="FIT"
              :saveTrigger="saveTrigger"
              :isSaved.sync="isSaved"/>
          </td>
        </tr>
        <tr>
          <th>코드<IconSvg v-if="!isModify" iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              :value.sync="model.code"
              :isDisabled="isModify && Boolean(model.code)"/>
            <p class="desc_info">💡 코드 생성 이후 수정붙가</p>
          </td>
          <th>상태</th>
          <td>
            <SwitchCheck
              :value.sync="statusSync"
              :isSwitchLeft="true"
              text="활성화"
              offText="비활성화" />
          </td>
        </tr>
        <tr>
          <th>우선순위<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              :value.sync="model.priority"
              valueType="number"
              :isBlockUnValueType="true"/>
          </td>
          <th>광고마크 (섹션)</th>
          <td>
            <SwitchCheck
              :value.sync="model.isAdvertisement"
              :isSwitchLeft="true"
              text="표기"
              offText="미표기" />
          </td>
        </tr>
        <tr>
          <th>타겟구분<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Selectbox
              maxWidth="300px"
              :dataList="'user_target' | getSelectDataList"
              :value.sync="model.target"/>
          </td>
          <th>광고위치<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Selectbox
              maxWidth="300px"
              :dataList="'advertisement_inventory' | getSelectDataList"
              :value.sync="model.inventory"/>
          </td>
        </tr>
        <tr v-if="isModify">
          <th>생성자</th>
          <td>{{ model.createdBy }}</td>
          <th>생성일시</th>
          <td>{{ model.createdAt | convertDateFormat('YYYY-MM-DD HH:mm') }}</td>
        </tr>
        <tr>
          <th>제목</th>
          <td colspan="3">
            <Input
              :value.sync="model.title"/>
          </td>
        </tr>
        <tr>
          <th>설명</th>
          <td colspan="3">
            <Input
              :value.sync="model.description"/>
          </td>
        </tr>
        <tr>
          <th>링크<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <Input
              :value.sync="model.link"/></td>
        </tr>
        <tr>
          <th>게시기간<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td colspan="3">
            <DateTimePicker
              :value.sync="model.startAt"/>
              <span class="txt_tbl"> ~ </span>
            <DateTimePicker
              :value.sync="model.endAt"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import DateTimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateTimePicker';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { convertDateFormat, dateDiff } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
// mixin
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin'

export default {
  name: 'AdvertisementDetailPopup',
  mixins:[FileSaveMixin],
  components: {
    Popup,
    TableView,
    IconSvg,
    Input,
    SwitchCheck,
    Selectbox,
    FileUpload,
    DateTimePicker,
    Button
  },
  props: {
    isModify: Boolean,
    model: Object,
    isVaild: Boolean,
  },
  computed: {
    statusSync:{
      get(){
        return this.model.status === 'REGISTERED';
      },
      set(val){
        let result = this.model.status;
        switch(val){
          case true : { result = 'REGISTERED'; break }
          case false : { result = 'UNREGISTERED'; break }
        }
        this.model.status = result;
      },
    },
    isEndDate() {
      let today = convertDateFormat(new Date(), 'YYYY-MM-DD');
      const passDay = dateDiff(today, this.data.endAt) > 0;
      const passDayColor = passDay ? 'tc_red' : '';
      return passDayColor;
    },
  },
  methods:{
    onClickComplete(){
      if(this.isModify){
        const requestCallback = () => {
          const changedSrc = this.model.imageList[0] ? this.model.imageList[0].src : '';
          if(this.model.objRaw.image != changedSrc){
            this.onSave();
          }else{
            this.$emit('onClickComplete',this.model);
          }
        }
        this.$store.dispatch('commonAlert/fetchAlertStart', {
          alertMsg: `광고를 수정하시겠습니까?`,
          closeBtnText: '취소',
          compeleteBtnText: '확인',
          confirmCallBack: requestCallback,
        });
      }else{
        this.onSave();
      }
    },
    onCompleteFileSave(){
      this.$emit('onClickComplete',this.model);
    }
  },
};
</script>
<style scoped>
.img_thmbnail {
  overflow: hidden;
  width:100%;
  max-width: 569px;
  max-height: 500px;
}
.img_thmbnail img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
